@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
 :root{
  --primary-color:#0fB8e0;
  --primary-dark: #0d2154;
}

span{
  color:#0fB8e0;
  color:var(--primary-color);
}
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

 body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
ul{
  list-style-type: none;
}
a{
  text-decoration: none;
  color: #fff;
}
p{
  font-size: 1.2rem;
  line-height: 1.4;
}
h1{
  font-size: 3.5rem;
}

h2{
  font-size: 3rem;
}
.container {
  max-width: 1240px;
  margin: auto;
  height: 100%;
  position: relative;
}

button{
  font-family: 'Roboto',sans-serif;
  padding: .7rem 1.5rem;
  margin: 1rem 0;
  background-color: #0fB8e0;
  background-color: var(--primary-color);
  border: 1px solid #0fB8e0;
  border: 1px solid var(--primary-color);
  color: #f8f8f8;
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  border-radius: 4px;
}

button:hover {
  background-color: #47dcff;
  border: 1px solid #47dcff;
}

@media screen and (max-width:940px) {
  h1{
    font-size: 3rem;
  }
  h2{
    font-size: 2.5rem;
  }
}









.navbar{
    width: 100%;
    height: 100px;
    position: absolute;
    z-index: 10;
}
.navbar .container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar .logo{
    display: flex;
    align-items: center;
    color: #fff;
    margin: 1 rem;
}

.navbar .logo .icon{
    font-size: 3rem;
    margin-right: 1rem;
    color: var(--primary-color);
}

.hamburger{
    display: none;
    padding: 1rem;
}
.navbar .nav-menu{
    height: 100%;
    align-items: center;
    display: flex;
    padding: 1rem;
}

.navbar li{
    padding: 1rem;
    font-weight: 600;
    color: #fff;
}
.navbar .icon {
    font-size: 1.4rem;
    color: #fff;
}

@media screen and (max-width:940px ) {
.navbar .hamburger {
    display: block;
    z-index: 15;
}
.navbar .nav-menu {
    position: absolute;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    top: 0;
    left: -100%;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0,.8);
    transition: 0.5s ease-in;
}

.navbar .active{
left: 0;
}
.navbar li {
    margin-right: 1rem;
    font-size: 2rem;
}

.navbar button{
    font-size: 1.8rem;
}


}



.hero {
    width: 100%;
    height: 100vh;
    background: url(/static/media/cyber5.578f672e.jpg) no-repeat center center/cover;
}

.hero .container{
    display: grid;
    grid-template-columns: repeat(2 , 1fr);
}

.hero .content{
    height: 75vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1 rem;
    margin-left: 6 rem;
}

.hero h1{
    color: #f8f8f8;
}
.hero.blue{
    color: var(--primary-color);
}
@media screen and ( max-width:940px){
    .hero .content{
        margin-left: 1rem;
    }
}
.data{
    width:100%;
    height: 100vh;
    position: relative;
    background: rgba(0,0,0,.1);
}

.data:before{
    content: '';
    position: absolute;
    background: url(/static/media/dataimg.bdbce586.jpg) no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
} 

.data .content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 45%;
    width: 50%;
}

.data .content p{
    margin: 1rem 0;
}


@media screen and (max-width:940px) {
    .data .container{
        left: 0;
        top: 0;
        width: 100%;
        height: 75%;
        padding: 1rem;
        background-color: rgba(255 , 255 , 255 , .7);
    }
    .data .container .content{
        margin: auto;
        padding: .5rem;
        left: 0;
        width: 100%;
    }
    
    
}


.cloud{
    width: 100%;
    height: 100vh;
    position: relative;
    background: rgba(0,0,0,.3);
}

.cloud:before{
content: '';
position: absolute;
background: url(/static/media/cloud2.0bb973d2.jpg) no-repeat center center/cover;
height: 100%;
width: 100%;
z-index: -1;
}

.cloud .content {
    
    position: absolute;
    left: 0;
    top: 20%;
    width: 50%;
    padding: 1rem;
}

.cloud h2 {
    color:#f8f8f8;

}

.cloud p {
    color: #f8f8f8;
    margin: 1rem 0;
}

@media screen and (max-width:940px) {
    .cloud .content{
        left: 0;
        width: 100%;
        height: 75vh;
        
    }
}



.footer{
    width: 100%;
    height: 60vh;
    position: relative;
    background: rgba(255.255,255,0.7);
}

.footer::before{
    content: '';
    position: absolute;
    background: url();
    height: 100%;
    width: 100%;
    z-index: -1;

}
.footer .container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer .top{
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
}

.footer .top .logo-footer{
    display: flex;
    align-items: center;
}

footer .top .icon{
font-size: 3rem;
margin-right: 1rem;
color: var(--primary-color);
cursor: pointer;
}

.footer h2{
    font-size: 2rem; 
}

.footer .col-container {
    display: grid;
    grid-template-columns:  1fr 1fr 1fr 1fr 2fr;
    padding: 1rem;
    width: 100%;
}

.footer h3{
margin: 1rem 0 2rem 0;
text-transform: uppercase;
}

.footer p{
    margin: .5rem 0;
}

.footer form{
    width: 100%;
    margin-bottom:.6rem ;
    position: relative;
}

.footer form input{
    padding: 1rem;
    width: 100%;
    outline: none;
    font-size: 1rem;
    border: 1px solid #aaa;
    border-radius: 4px;
    color: #333;
}

.footer form input:focus{
    color: #333;
    outline: var(--primary-color);
    box-shadow: 0 0 8px 0 var(--primary-color);
}

.footer form .mail-icon {
    position: absolute;
    font-size: 1.4rem;
    margin: 1rem;
    right: .2rem;
    color: #aaa;
    cursor: pointer;
}

.footer form .social-icon{
    margin: 1rem 1rem 1rem 0;
    padding: .6rem;
    background-color: var(--primary-dark);
    border-radius: 4px;
    color: #f8f8f8;
    font-size: 3rem;
}

@media screen and (max-width:940px) {
    .footer{
        height:100%;
    }

    .footer .col-container{
        grid-template-columns: 2fr 2fr;
    }
    .footer form{
        grid-column: 1 / span2;
    }

}


.recovery {
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,.4);
    position: relative;
}

.recovery:before{
    content: '';
    position: absolute;
    background: url(/static/media/cyber4.19fdddf5.jpg) no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;

}

.recovery .content{
    position: absolute;
    top: 30%;
    width: 50%;
    left: 0;
    padding: 1rem;
}

.recovery h2{
    color: #f8f8f8;
}

.recovery p {
    color: #f8f8f8;
    margin: 1rem 0;
}


@media screen and (max-width:940px) {
    .recovery .content{
        width: 100%;
    }
}


.contact {
    width: 100;
    height:100vh;
    position: relative;
    background: rgba(0,0,0,.5);
}

.contact:before{
    content: '';
    position: absolute;
    background: url(/static/media/cyber1.cc01613a.jpg) no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;

}

.contact .container .form-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #f8f8f8;
}

.contact .form {
    width: 50%;
    margin: 1rem;
}

.contact .container .form-container div{
    display: flex;
    flex-direction: column;
}

.contact form label{
    margin: 2rem 0 .7rem 0;
    display: none;
}
.contact form input , textarea{
    padding: .5rem;
    font-size: 1.2rem;
    color: #f8f8f8;
    margin:1rem 0 ;
    font-family: 'Roboto',sans-serif;
    background: rgba(0,0,0,.15);
    box-shadow: 0 8px 32px 0 rgba(31,38,135,0.37);
   
    border-radius: 4px;
    border: 1px solid rgba(0,0,0,.15);

}

.contact form input:focus,textarea:focus{
    outline: var(--primary-color);
    box-shadow: 0 0 12px 0 var(--primary-color);
}

@media screen and (max-width:940px) {
    .contact form{
        padding: .5rem;
        width: 100%;
    }
    
}



 @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap'); 
:root{
  --primary-color:#0fB8e0;
  --primary-dark: #0d2154;
}

span{
  color:var(--primary-color);
}
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

 body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
ul{
  list-style-type: none;
}
a{
  text-decoration: none;
  color: #fff;
}
p{
  font-size: 1.2rem;
  line-height: 1.4;
}
h1{
  font-size: 3.5rem;
}

h2{
  font-size: 3rem;
}
.container {
  max-width: 1240px;
  margin: auto;
  height: 100%;
  position: relative;
}

button{
  font-family: 'Roboto',sans-serif;
  padding: .7rem 1.5rem;
  margin: 1rem 0;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  color: #f8f8f8;
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  border-radius: 4px;
}

button:hover {
  background-color: #47dcff;
  border: 1px solid #47dcff;
}

@media screen and (max-width:940px) {
  h1{
    font-size: 3rem;
  }
  h2{
    font-size: 2.5rem;
  }
}









.footer{
    width: 100%;
    height: 60vh;
    position: relative;
    background: rgba(255.255,255,0.7);
}

.footer::before{
    content: '';
    position: absolute;
    background: url();
    height: 100%;
    width: 100%;
    z-index: -1;

}
.footer .container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer .top{
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
}

.footer .top .logo-footer{
    display: flex;
    align-items: center;
}

footer .top .icon{
font-size: 3rem;
margin-right: 1rem;
color: var(--primary-color);
cursor: pointer;
}

.footer h2{
    font-size: 2rem; 
}

.footer .col-container {
    display: grid;
    grid-template-columns:  1fr 1fr 1fr 1fr 2fr;
    padding: 1rem;
    width: 100%;
}

.footer h3{
margin: 1rem 0 2rem 0;
text-transform: uppercase;
}

.footer p{
    margin: .5rem 0;
}

.footer form{
    width: 100%;
    margin-bottom:.6rem ;
    position: relative;
}

.footer form input{
    padding: 1rem;
    width: 100%;
    outline: none;
    font-size: 1rem;
    border: 1px solid #aaa;
    border-radius: 4px;
    color: #333;
}

.footer form input:focus{
    color: #333;
    outline: var(--primary-color);
    box-shadow: 0 0 8px 0 var(--primary-color);
}

.footer form .mail-icon {
    position: absolute;
    font-size: 1.4rem;
    margin: 1rem;
    right: .2rem;
    color: #aaa;
    cursor: pointer;
}

.footer form .social-icon{
    margin: 1rem 1rem 1rem 0;
    padding: .6rem;
    background-color: var(--primary-dark);
    border-radius: 4px;
    color: #f8f8f8;
    font-size: 3rem;
}

@media screen and (max-width:940px) {
    .footer{
        height:100%;
    }

    .footer .col-container{
        grid-template-columns: 2fr 2fr;
    }
    .footer form{
        grid-column: 1 / span2;
    }

}

